import { getCurrentUser } from 'helpers/Utils';

export const HOST = 'https://api.krypzo.io';
const getQueryParams = (params) => {
  let query = '';
  if (Object.keys(params).length !== 0) {
    query = '&';
    Object.entries(params).forEach(([key, value]) => {
      query = query.concat(`${key}=${value}`);
    });
  }
  return query;
};


const getHeaders = (params) => {
  const headers = new Headers();
  const userInfo = getCurrentUser();
  if (userInfo?.token) {
    headers.append('Authorization', `Bearer ${userInfo?.token}`);
  }
  headers.append("Content-Type", "application/json")
  Object.entries(params || {}).forEach(([key, value]) => {
    headers.append(key, value);
  });
  return headers;
};
const generateBody = (params) => {
  // const formData = new FormData();
  // Object.entries(params).forEach(([key, value]) => {
  //   formData.append(key, JSON.stringify(value));
  // });
  // return formData;
  return JSON.stringify(params);
};
export const requestGET = async (endpoint, headers, queryParams) => {
  const requestURL = `${HOST}/${endpoint}${getQueryParams(queryParams)}`;
  const getRequestHeaders = getHeaders(headers);
  console.log(getRequestHeaders,"getRequestHeaders");
  return fetch(requestURL, {
    headers: getRequestHeaders,
  }).then((rawData) => rawData.json());
};
export const requestPOST = async (
  endpoint,
  headers,
  queryParams,
  bodyParams
) => {
  const requestURL = `${HOST}/${endpoint}${getQueryParams(queryParams)}`;
  return fetch(requestURL, {
    method: 'POST',
    headers: getHeaders(headers),
    body: generateBody(bodyParams),
  }).then((rawData) => rawData.json());
};
export const requestDELETE = async (
  endpoint,
  headers,
  queryParams,
  bodyParams
) => {
  const requestURL = `${HOST}/${endpoint}${getQueryParams(queryParams)}`;
  const getRequestHeaders = getHeaders(headers);
  return fetch(requestURL, {
    method: 'DELETE',
    body: generateBody(bodyParams),
  }).then((rawData) => rawData.json());
};
